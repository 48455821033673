/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://90wsrzuat0.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://tcqd72rzjjbtfkhp5fhkrvcy3e.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:56c807ef-e476-4e76-9e7c-7f93545275f6",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_AeHrsT2Xi",
    "aws_user_pools_web_client_id": "32rhtsioalkhsbg1ehjotdihp3",
    "oauth": {
        "domain": "auth.cevapro.com.br",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://cevapro.com.br/",
        "redirectSignOut": "https://cevapro.com.br/",
        "responseType": "code"
    },
    "aws_user_files_s3_bucket": "cevaprobucket113718-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
