import React, { Component } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import './scss/style.scss'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import Amplify from 'aws-amplify'
import awsconfig from './aws-exports'
import CookieConsent from "react-cookie-consent";

Amplify.configure(awsconfig)

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

// optional configuration
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}

class App extends Component {
  render() {
    return (
      <AlertProvider template={AlertTemplate} {...options}>
        <CookieConsent location="bottom"
          style={{ textAlign: "center", backgroundColor: "#274571", display: "block" }}
          buttonStyle={{
            borderRadius: "5px", color: "white",
            fontWeight: "bolder",
            backgroundColor: "transparent",
            textDecoration: "underline",
            marginTop: "0px",

          }}
          buttonText="CONTINUAR"
          cookieName="cookieConsent" overlay>
          Nós usamos cookies e outras tecnologias semelhantes para melhorar a sua experiência em nossos serviços. <br />
                    Ao utilizar nossos serviços, você concorda com a nossa política de cookies. <br />
                    Para saber mais sobre cookies, <a target="_blank" style={{ color: "white", textDecoration: "underline" }} href="https://www.ceva.com.br/Footer-links/Politica-de-Privacidade#cookies">clique aqui.</a> <br />
                    Para ver a nossa política de privacidade, <a target="_blank" style={{ color: "white", textDecoration: "underline" }} href="https://www.ceva.com.br/Footer-links/Politica-de-Privacidade#privacy">clique aqui.</a>
        </CookieConsent>
        <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
              <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
            </Switch>
          </React.Suspense>
        </HashRouter>
      </AlertProvider>

    )
  }
}

export default App
